import { Component, NgModule } from '@angular/core';
import { CommonModule, IMAGE_CONFIG, PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckSquare, faSquare, far } from '@fortawesome/free-regular-svg-icons';
import { fa0, fa1, faBars, faRssSquare, faSearch, fas } from '@fortawesome/free-solid-svg-icons';
import { fa42Group, faGithub, faMedium, faStackOverflow, fab } from '@fortawesome/free-brands-svg-icons';
import { RouterOutlet } from '@angular/router';

import * as $ from "jquery";
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule} from '@angular/material/paginator';

import { NgOptimizedImage } from '@angular/common'
import {MatTableModule,} from '@angular/material/table';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule } from 'ng-recaptcha';
import { SplitCasePipe } from './split.pipe';

@NgModule({
  declarations:[SplitCasePipe],
  imports: [
    CommonModule, FormsModule, RouterOutlet, FontAwesomeModule ,  
    NgxGpAutocompleteModule,  MatFormFieldModule, MatNativeDateModule, MatDatepickerModule, MatInputModule,MatSelectModule, HttpClientModule,
    MatDialogModule,  NgOptimizedImage, MatTableModule,  MatPaginatorModule, RecaptchaModule
    
    
  ],
  exports:[FontAwesomeModule, CommonModule, FormsModule, RouterOutlet, 
    NgxGpAutocompleteModule, MatFormFieldModule, MatNativeDateModule, MatDatepickerModule, MatInputModule,MatSelectModule, HttpClientModule, 
    MatDialogModule,  NgOptimizedImage, MatTableModule,  MatPaginatorModule , RecaptchaModule, SplitCasePipe],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true }},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: PRECONNECT_CHECK_BLOCKLIST, useValue:'https://onedropcab.in'},
    
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920]
      }
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyCueSJROSfzAZKzFY5eCaVphykM3Yftc4I',
        libraries: ['places','geometry'],
        
      })
    },
    {provide: 'googleTagManagerId',  useValue: 'GTM-M3BV5W63',}, 
      
  ],

})

export class SharedModule {

  public static isMobileMenu:boolean=false;
  

 
  constructor(library: FaIconLibrary){
    library.addIconPacks(fab,fas,far)
    
    
  }
 
 }
